/* src/components/directdebit/DirectDebitRunManager.css */
.bg-yellow {
    background-color: #fff3cd;
}

.bg-green {
    background-color: #d4edda;
}

.bg-red {
    background-color: #f8d7da;
}

.bg-grey {
    background-color: #e2e3e5;
}

.bg-light-red {
    background-color: #f5c6cb;
}